import logo from '../newsletter/logo-bpcnewsletter.svg';
import './style.scss';

function Newsletter() {
  return (
    <>
      <div className="newsletter w-screen">
        <div className="newsletter__container">
          <div className="newsletter__wrap">
            <img className="newsletter__logo" src={logo} alt="BPC LOGO" />
            <p class="newsletter__text">
              BOTANICAL POOL
              CLUBの最新ニュースやイベント、ユニークなストーリーなど、エクスクルーシブなコンテンツをいち早くお届けします。
            </p>
          </div>
          <iframe
            className="newsletter__iframe"
            src="https://info.botanicalpoolclub.com/l/1012642/2023-01-19/ty/"
            allowtransparency="true"
            title="BPC Newsletter"
          ></iframe>
        </div>
      </div>
    </>
  );
}

export default Newsletter;
