import { useEffect, useState, useRef } from 'react';
import { Navigation, EffectFade } from 'swiper';
import { useInView } from 'react-intersection-observer';
import { Swiper, SwiperSlide } from 'swiper/react';
import Transition from '../../components/transition';
import { BackgroundWhite } from '../../components/Background';
import Header from '../../components/menu';
import Weather from '../../components/weather';
import Footer from '../../components/footer';
import { ReactComponent as NavIcon } from './sp-nav-icon.svg';
import playbutton from '../../play-button.svg';
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/effect-fade';
import './style.scss';

function Gallary() {
  document.title = 'GALLERY | BOTANICAL POOL CLUB | OFFICIAL TEASER SITE';
  const [modalOpen, setModalOpen] = useState(false);
  const [navOpen, setNavOpen] = useState(false);
  const [navName, setNavName] = useState('hotel');

  const [videoPlayer, setVideoPlayer] = useState(false);
  const overlayEl = useRef();
  const popupEl = useRef();
  const video1El = useRef();

  function clickFunc1() {
    video1El.current.load();
    video1El.current.play();
    setVideoPlayer(true);
  }

  // const { ref: animationEl1, inView: hotelIsVisible } = useInView({
  //   triggerOnce: true,
  // });
  // const { ref: animationEl2, inView: poolIsVisible } = useInView({
  //   triggerOnce: true,
  // });
  // const { ref: animationEl3, inView: eatIsVisible } = useInView({
  //   triggerOnce: true,
  // });
  // const { ref: animationEl4, inView: exploreIsVisible } = useInView({
  //   triggerOnce: true,
  // });
  // const { ref: animationEl5, inView: storiesIsVisible } = useInView({
  //   triggerOnce: true,
  // });

  const swiperRef = useRef();
  const hotelRef = useRef();
  const poolRef = useRef();
  const eatanddrinkRef = useRef();
  const exploreRef = useRef();
  const storiesRef = useRef();

  function disableScroll(e) {
    e.preventDefault();
  }

  useEffect(() => {
    if (navName === 'hotel') {
      hotelRef.current.style.display = 'none';
      poolRef.current.style.display = 'block';
      eatanddrinkRef.current.style.display = 'block';
      exploreRef.current.style.display = 'block';
      storiesRef.current.style.display = 'block';
    } else if (navName === 'pool') {
      hotelRef.current.style.display = 'block';
      poolRef.current.style.display = 'none';
      eatanddrinkRef.current.style.display = 'block';
      exploreRef.current.style.display = 'block';
      storiesRef.current.style.display = 'block';
    } else if (navName === 'eat & drink') {
      hotelRef.current.style.display = 'block';
      poolRef.current.style.display = 'block';
      eatanddrinkRef.current.style.display = 'none';
      exploreRef.current.style.display = 'block';
      storiesRef.current.style.display = 'block';
    } else if (navName === 'explore') {
      hotelRef.current.style.display = 'block';
      poolRef.current.style.display = 'block';
      eatanddrinkRef.current.style.display = 'block';
      exploreRef.current.style.display = 'none';
      storiesRef.current.style.display = 'block';
    } else if (navName === 'stories') {
      hotelRef.current.style.display = 'block';
      poolRef.current.style.display = 'block';
      eatanddrinkRef.current.style.display = 'block';
      exploreRef.current.style.display = 'block';
      storiesRef.current.style.display = 'none';
    } else {
      hotelRef.current.style.display = 'block';
      poolRef.current.style.display = 'block';
      eatanddrinkRef.current.style.display = 'block';
      exploreRef.current.style.display = 'block';
      storiesRef.current.style.display = 'block';
    }
  });

  useEffect(() => {
    if (modalOpen) {
      document.addEventListener('touchmove', disableScroll, { passive: false });
      document.addEventListener('mousewheel', disableScroll, {
        passive: false,
      });
    }

    const escapeAction = (e) => {
      if (modalOpen && e.key === 'Escape') {
        setModalOpen(false);
      }
    };
    document.addEventListener('keydown', escapeAction);

    const vEscapeAction = (e) => {
      video1El.current.pause();
      setVideoPlayer(false);
    };

    if (overlayEl.current != null) {
      document.addEventListener('keydown', vEscapeAction);
      overlayEl.current.addEventListener('click', vEscapeAction);
      popupEl.current.addEventListener('click', vEscapeAction);
    }

    if (videoPlayer) {
      document.addEventListener('touchmove', disableScroll, {
        passive: false,
      });
      document.addEventListener('mousewheel', disableScroll, {
        passive: false,
      });
    }

    return () => {
      document.removeEventListener('touchmove', disableScroll, {
        passive: false,
      });
      document.removeEventListener('mousewheel', disableScroll, {
        passive: false,
      });
      document.removeEventListener('keydown', escapeAction);

      document.removeEventListener('touchmove', disableScroll, {
        passive: false,
      });
      document.removeEventListener('mousewheel', disableScroll, {
        passive: false,
      });
      if (overlayEl.current != null) {
        document.removeEventListener('keydown', vEscapeAction);
        overlayEl.current.addEventListener('click', vEscapeAction);
        popupEl.current.addEventListener('click', vEscapeAction);
      }
    };
  });

  useEffect(() => {
    const smoothScrollTrigger = document.querySelectorAll('a[href^="#"]');
    const el = document.querySelector('.menu__container');
    for (let i = 0; i < smoothScrollTrigger.length; i++) {
      smoothScrollTrigger[i].addEventListener('click', (e) => {
        e.preventDefault();
        let href = smoothScrollTrigger[i].getAttribute('href');
        let targetElement = document.getElementById(href.replace('#', ''));
        const rect = targetElement.getBoundingClientRect().top;
        const offset = window.pageYOffset;
        const gap = el.clientHeight;
        const target = rect + offset - gap;
        window.scrollTo({
          top: target,
          behavior: 'smooth',
        });
      });
    }
  });

  return (
    <>
      <Header logo="menu-logo-black" background="menu-white" />
      <Weather isColor />
      <Transition />
      <main>
        <BackgroundWhite />
        <div className="gp__pc-nav">
          <ul>
            <li className="font-serif">
              <a href="#hotel">HOTEL</a>
            </li>
            <li className="font-serif">
              <a href="#pool">POOL</a>
            </li>
            <li className="font-serif">
              <a href="#eat-and-drink">EAT & DRINK</a>
            </li>
            <li className="font-serif">
              <a href="#explore">EXPLORE</a>
            </li>
            <li className="font-serif">
              <a href="#stories">BPC STORIES</a>
            </li>
          </ul>
        </div>

        <div
          className="gp__sp-nav"
          onClick={() => {
            setNavOpen((prevState) => !prevState);
          }}
        >
          <div>
            <NavIcon className="gp__sp-nav--icon" />
          </div>
          <div>
            <ul>
              <li className="font-serif uppercase">{navName}</li>
            </ul>
          </div>
        </div>

        <div
          className="gp__sp"
          onClick={() => {
            setNavOpen(false);
          }}
          style={{ display: navOpen ? 'flex' : 'none' }}
        >
          <ul>
            <li
              className="font-serif"
              ref={hotelRef}
              onClick={() => {
                setNavName('hotel');
              }}
            >
              <a href="#hotel">HOTEL</a>
            </li>
            <li
              className="font-serif"
              ref={poolRef}
              onClick={() => {
                setNavName('pool');
              }}
            >
              <a href="#pool">POOL</a>
            </li>
            <li
              className="font-serif"
              ref={eatanddrinkRef}
              onClick={() => {
                setNavName('eat & drink');
              }}
            >
              <a href="#eat-and-drink">EAT & DRINK</a>
            </li>
            <li
              className="font-serif"
              ref={exploreRef}
              onClick={() => {
                setNavName('explore');
              }}
            >
              <a href="#explore">EXPLORE</a>
            </li>
            <li
              className="font-serif"
              ref={storiesRef}
              onClick={() => {
                setNavName('stories');
              }}
            >
              <a href="#stories">BPC STORIES</a>
            </li>
          </ul>
        </div>

        <section className="gp__container">
          <div className="gp__hero">
            <h1 className="font-serif">GALLERY</h1>
          </div>

          <div
            // className={`gp__box inview ${hotelIsVisible ? 'inview' : ''}`}
            className={`gp__box inview`}
            id="hotel"
            // ref={animationEl1}
          >
            <div>
              <h3 className="font-serif gp__title">HOTEL</h3>
            </div>
            <div className="gp__grid-wrapper">
              <div
                className="gp__grid-inner"
                onClick={() => {
                  setModalOpen((prevState) => !prevState);
                  console.log(modalOpen);
                  swiperRef.current.swiper.slideTo(0);
                }}
              >
                <img
                  className="gp__img"
                  src={`${process.env.PUBLIC_URL}/Hotel_01.jpg`}
                  alt=""
                />
              </div>
              <div
                className="gp__grid-inner pc-grid-big sp-grid-tall"
                onClick={() => {
                  setModalOpen((prevState) => !prevState);
                  swiperRef.current.swiper.slideTo(1);
                }}
              >
                <img
                  className="gp__img"
                  src={`${process.env.PUBLIC_URL}/Hotel_02.jpg`}
                  alt=""
                />
              </div>
              <div
                className="gp__grid-inner pc-grid-tall sp-grid-tall"
                onClick={() => {
                  setModalOpen((prevState) => !prevState);
                  console.log(modalOpen);
                  swiperRef.current.swiper.slideTo(2);
                }}
              >
                <img
                  className="gp__img"
                  src={`${process.env.PUBLIC_URL}/Hotel_03.jpg`}
                  alt=""
                />
              </div>
              <div
                className="gp__grid-inner sp-grid-tall"
                onClick={() => {
                  setModalOpen((prevState) => !prevState);
                  console.log(modalOpen);
                  swiperRef.current.swiper.slideTo(3);
                }}
              >
                <img
                  className="gp__img"
                  src={`${process.env.PUBLIC_URL}/Hotel_04.jpg`}
                  alt=""
                />
              </div>
              <div
                className="gp__grid-inner"
                onClick={() => {
                  setModalOpen((prevState) => !prevState);
                  console.log(modalOpen);
                  swiperRef.current.swiper.slideTo(4);
                }}
              >
                <img
                  className="gp__img"
                  src={`${process.env.PUBLIC_URL}/Hotel_05.jpg`}
                  alt=""
                />
              </div>
            </div>
          </div>

          <div
            // className={`gp__box ${poolIsVisible ? 'inview' : ''}`}
            className={`gp__box inview`}
            id="pool"
            // ref={animationEl2}
          >
            <div>
              <h3 className="font-serif gp__title">POOL</h3>
            </div>
            <div className="gp__grid-wrapper">
              <div
                className="gp__grid-inner sp-grid-tall"
                onClick={() => {
                  setModalOpen((prevState) => !prevState);
                  console.log(modalOpen);
                  swiperRef.current.swiper.slideTo(5);
                }}
              >
                <img
                  className="gp__img"
                  src={`${process.env.PUBLIC_URL}/Pool_01.jpg`}
                  alt=""
                />
              </div>
              <div
                className="gp__grid-inner pc-grid-tall"
                onClick={() => {
                  setModalOpen((prevState) => !prevState);
                  console.log(modalOpen);
                  swiperRef.current.swiper.slideTo(6);
                }}
              >
                <img
                  className="gp__img"
                  src={`${process.env.PUBLIC_URL}/Pool_02.jpg`}
                  alt=""
                />
              </div>
              <div
                className="gp__grid-inner"
                onClick={() => {
                  setModalOpen((prevState) => !prevState);
                  console.log(modalOpen);
                  swiperRef.current.swiper.slideTo(7);
                }}
              >
                <img
                  className="gp__img"
                  src={`${process.env.PUBLIC_URL}/Pool_03.jpg`}
                  alt=""
                />
              </div>
              <div
                className="gp__grid-inner pc-grid-tall"
                onClick={() => {
                  setModalOpen((prevState) => !prevState);
                  console.log(modalOpen);
                  swiperRef.current.swiper.slideTo(8);
                }}
              >
                <img
                  className="gp__img"
                  src={`${process.env.PUBLIC_URL}/Pool_04.jpg`}
                  alt=""
                />
              </div>
              <div
                className="gp__grid-inner pc-grid-tall trumpsLandscape"
                onClick={() => {
                  setModalOpen((prevState) => !prevState);
                  console.log(modalOpen);
                  swiperRef.current.swiper.slideTo(9);
                }}
              >
                <img
                  className="gp__img"
                  src={`${process.env.PUBLIC_URL}/Pool_05.jpg`}
                  alt=""
                />
              </div>
              <div
                className="gp__grid-inner trumpsLandscape"
                onClick={() => {
                  setModalOpen((prevState) => !prevState);
                  console.log(modalOpen);
                  swiperRef.current.swiper.slideTo(10);
                }}
              >
                <img
                  className="gp__img"
                  src={`${process.env.PUBLIC_URL}/Pool_06.jpg`}
                  alt=""
                />
              </div>
              <div
                className="gp__grid-inner sp-grid-big trumpsPortrait"
                onClick={() => {
                  setModalOpen((prevState) => !prevState);
                  console.log(modalOpen);
                  swiperRef.current.swiper.slideTo(9);
                }}
              >
                <img
                  className="gp__img"
                  src={`${process.env.PUBLIC_URL}/Pool_05.jpg`}
                  alt=""
                />
              </div>
              <div
                className="gp__grid-inner trumpsPortrait"
                onClick={() => {
                  setModalOpen((prevState) => !prevState);
                  console.log(modalOpen);
                  swiperRef.current.swiper.slideTo(10);
                }}
              >
                <img
                  className="gp__img"
                  src={`${process.env.PUBLIC_URL}/Pool_06.jpg`}
                  alt=""
                />
              </div>
            </div>
          </div>

          <div
            // className={`gp__box ${eatIsVisible ? 'inview' : ''}`}
            className={`gp__box inview`}
            id="eat-and-drink"
            // ref={animationEl3}
          >
            <div>
              <h3 className="font-serif gp__title">EAT & DRINK</h3>
            </div>
            <div className="gp__grid-wrapper">
              <div
                className="gp__grid-inner pc-grid-big sp-grid-tall"
                onClick={() => {
                  setModalOpen((prevState) => !prevState);
                  console.log(modalOpen);
                  swiperRef.current.swiper.slideTo(11);
                }}
              >
                <img
                  className="gp__img"
                  src={`${process.env.PUBLIC_URL}/EatDrink_01.jpg`}
                  alt=""
                />
              </div>
              <div
                className="gp__grid-inner"
                onClick={() => {
                  setModalOpen((prevState) => !prevState);
                  console.log(modalOpen);
                  swiperRef.current.swiper.slideTo(12);
                }}
              >
                <img
                  className="gp__img"
                  src={`${process.env.PUBLIC_URL}/EatDrink_02.jpg`}
                  alt=""
                />
              </div>
              <div
                className="gp__grid-inner"
                onClick={() => {
                  setModalOpen((prevState) => !prevState);
                  console.log(modalOpen);
                  swiperRef.current.swiper.slideTo(13);
                }}
              >
                <img
                  className="gp__img"
                  src={`${process.env.PUBLIC_URL}/EatDrink_03.jpg`}
                  alt=""
                />
              </div>
            </div>
          </div>

          <div
            // className={`gp__box ${exploreIsVisible ? 'inview' : ''}`}
            className={`gp__box inview`}
            id="explore"
            // ref={animationEl4}
          >
            <div>
              <h3 className="font-serif gp__title">EXPLORE</h3>
            </div>
            <div className="gp__grid-wrapper">
              <div
                className="gp__grid-inner"
                onClick={() => {
                  setModalOpen((prevState) => !prevState);
                  console.log(modalOpen);
                  swiperRef.current.swiper.slideTo(14);
                }}
              >
                <img
                  className="gp__img"
                  src={`${process.env.PUBLIC_URL}/Explore_01.jpg`}
                  alt=""
                />
              </div>
              <div
                className="gp__grid-inner pc-grid-tall sp-grid-tall"
                onClick={() => {
                  setModalOpen((prevState) => !prevState);
                  console.log(modalOpen);
                  swiperRef.current.swiper.slideTo(15);
                }}
              >
                <img
                  className="gp__img"
                  src={`${process.env.PUBLIC_URL}/Explore_02.jpg`}
                  alt=""
                />
              </div>
              <div
                className="gp__grid-inner sp-grid-tall"
                onClick={() => {
                  setModalOpen((prevState) => !prevState);
                  console.log(modalOpen);
                  swiperRef.current.swiper.slideTo(16);
                }}
              >
                <img
                  className="gp__img"
                  src={`${process.env.PUBLIC_URL}/Explore_03.jpg`}
                  alt=""
                />
              </div>
              <div
                className="gp__grid-inner sp-grid-tall"
                onClick={() => {
                  setModalOpen((prevState) => !prevState);
                  console.log(modalOpen);
                  swiperRef.current.swiper.slideTo(17);
                }}
              >
                <img
                  className="gp__img"
                  src={`${process.env.PUBLIC_URL}/Explore_04.jpg`}
                  alt=""
                />
              </div>
              <div
                className="gp__grid-inner"
                onClick={() => {
                  setModalOpen((prevState) => !prevState);
                  console.log(modalOpen);
                  swiperRef.current.swiper.slideTo(18);
                }}
              >
                <img
                  className="gp__img"
                  src={`${process.env.PUBLIC_URL}/Explore_05.jpg`}
                  alt=""
                />
              </div>
            </div>
          </div>
          <div
            // className={`gp__box ${storiesIsVisible ? 'inview' : ''}`}
            className={`gp__box inview`}
            id="stories"
            // ref={animationEl5}
          >
            <div>
              <h3 className="font-serif gp__title">BPC STORIES</h3>
            </div>
            <div className="gp__grid-stories" onClick={clickFunc1}>
              <div className="gp__grid-inner sp-grid-tall">
                <div className="stories__text">
                  <div>
                    <img
                      className="stories__play"
                      src={playbutton}
                      alt="Play Button"
                    />
                  </div>
                  <div>
                    <p className="font-serif caption text-white tracking-loose">
                      Play the story
                    </p>
                  </div>
                </div>
                <img
                  className="gp__img"
                  src={`${process.env.PUBLIC_URL}/Stories_01.jpg`}
                  alt=""
                />
              </div>
              <div className="gp__grid-inner sp-grid-tall">
                <div className="gp__overlay"></div>
                <div className="stories__text">
                  <p className="font-serif caption text-white tracking-loose">
                    Coming Soon
                  </p>
                </div>
                <img
                  className="gp__img"
                  src={`${process.env.PUBLIC_URL}/Stories_02.jpg`}
                  alt=""
                />
              </div>
              <div className="gp__grid-inner sp-grid-tall">
                <div className="gp__overlay"></div>
                <div className="stories__text">
                  <p className="font-serif caption text-white tracking-loose">
                    Coming Soon
                  </p>
                </div>
                <img
                  className="gp__img"
                  src={`${process.env.PUBLIC_URL}/Stories_03.jpg`}
                  alt=""
                />
              </div>
              <div className="gp__grid-inner sp-grid-tall">
                <div className="gp__overlay"></div>
                <div className="stories__text">
                  <p className="font-serif caption text-white tracking-loose">
                    Coming Soon
                  </p>
                </div>
                <img
                  className="gp__img"
                  src={`${process.env.PUBLIC_URL}/Stories_04.jpg`}
                  alt=""
                />
              </div>
            </div>
          </div>

          <div
            className="stories__layer"
            style={{ display: videoPlayer ? 'block' : 'none' }}
            ref={overlayEl}
          ></div>
          <div
            className="stories__popup"
            style={{ display: videoPlayer ? 'flex' : 'none' }}
            ref={popupEl}
          >
            <div>
              <video
                class="stories__player"
                src="https://cdn.botanicalpoolclub.com/files/01/videos/230423_01_BPC_POOLSIDE.mp4"
                ref={video1El}
              ></video>
            </div>
          </div>
        </section>

        <section
          className="gp__modal"
          style={{ display: modalOpen ? 'flex' : 'none' }}
        >
          <div
            className="gp__modal-close"
            onClick={() => {
              setModalOpen((prevState) => !prevState);
            }}
          >
            <div>
              <span></span>
              <span></span>
            </div>
          </div>
          <div
            id="gp-button-prev"
            className="swiper-button-prev gallery-button"
          ></div>
          <Swiper
            ref={swiperRef}
            modules={[Navigation]}
            slidesPerGroup={1}
            slidesPerView={1}
            spaceBetween={0}
            initialSlide={0}
            effect="fade"
            navigation={{
              prevEl: '#gp-button-prev',
              nextEl: '#gp-button-next',
            }}
          >
            <SwiperSlide>
              <div className="gp__swiper-content">
                <img
                  className="gp__swiper-img"
                  src={`${process.env.PUBLIC_URL}/Hotel_01.jpg`}
                  alt=""
                />
              </div>
            </SwiperSlide>
            <SwiperSlide>
              <div className="gp__swiper-content">
                <img
                  className="gp__swiper-img"
                  src={`${process.env.PUBLIC_URL}/Hotel_02.jpg`}
                  alt=""
                />
              </div>
            </SwiperSlide>
            <SwiperSlide>
              <div className="gp__swiper-content">
                <img
                  className="gp__swiper-img"
                  src={`${process.env.PUBLIC_URL}/Hotel_03.jpg`}
                  alt=""
                />
              </div>
            </SwiperSlide>
            <SwiperSlide>
              <div className="gp__swiper-content">
                <img
                  className="gp__swiper-img"
                  src={`${process.env.PUBLIC_URL}/Hotel_04.jpg`}
                  alt=""
                />
              </div>
            </SwiperSlide>
            <SwiperSlide>
              <div className="gp__swiper-content">
                <img
                  className="gp__swiper-img"
                  src={`${process.env.PUBLIC_URL}/Hotel_05.jpg`}
                  alt=""
                />
              </div>
            </SwiperSlide>
            <SwiperSlide>
              <div className="gp__swiper-content">
                <img
                  className="gp__swiper-img"
                  src={`${process.env.PUBLIC_URL}/Pool_01.jpg`}
                  alt=""
                />
              </div>
            </SwiperSlide>
            <SwiperSlide>
              <div className="gp__swiper-content">
                <img
                  className="gp__swiper-img"
                  src={`${process.env.PUBLIC_URL}/Pool_02.jpg`}
                  alt=""
                />
              </div>
            </SwiperSlide>
            <SwiperSlide>
              <div className="gp__swiper-content">
                <img
                  className="gp__swiper-img"
                  src={`${process.env.PUBLIC_URL}/Pool_03.jpg`}
                  alt=""
                />
              </div>
            </SwiperSlide>
            <SwiperSlide>
              <div className="gp__swiper-content">
                <img
                  className="gp__swiper-img"
                  src={`${process.env.PUBLIC_URL}/Pool_04.jpg`}
                  alt=""
                />
              </div>
            </SwiperSlide>
            <SwiperSlide>
              <div className="gp__swiper-content">
                <img
                  className="gp__swiper-img"
                  src={`${process.env.PUBLIC_URL}/Pool_05.jpg`}
                  alt=""
                />
              </div>
            </SwiperSlide>
            <SwiperSlide>
              <div className="gp__swiper-content">
                <img
                  className="gp__swiper-img"
                  src={`${process.env.PUBLIC_URL}/Pool_06.jpg`}
                  alt=""
                />
              </div>
            </SwiperSlide>
            <SwiperSlide>
              <div className="gp__swiper-content">
                <img
                  className="gp__swiper-img"
                  src={`${process.env.PUBLIC_URL}/EatDrink_01.jpg`}
                  alt=""
                />
              </div>
            </SwiperSlide>
            <SwiperSlide>
              <div className="gp__swiper-content">
                <img
                  className="gp__swiper-img"
                  src={`${process.env.PUBLIC_URL}/EatDrink_02.jpg`}
                  alt=""
                />
              </div>
            </SwiperSlide>
            <SwiperSlide>
              <div className="gp__swiper-content">
                <img
                  className="gp__swiper-img"
                  src={`${process.env.PUBLIC_URL}/EatDrink_03.jpg`}
                  alt=""
                />
              </div>
            </SwiperSlide>
            <SwiperSlide>
              <div className="gp__swiper-content">
                <img
                  className="gp__swiper-img"
                  src={`${process.env.PUBLIC_URL}/Explore_01.jpg`}
                  alt=""
                />
              </div>
            </SwiperSlide>
            <SwiperSlide>
              <div className="gp__swiper-content">
                <img
                  className="gp__swiper-img"
                  src={`${process.env.PUBLIC_URL}/Explore_02.jpg`}
                  alt=""
                />
              </div>
            </SwiperSlide>
            <SwiperSlide>
              <div className="gp__swiper-content">
                <img
                  className="gp__swiper-img"
                  src={`${process.env.PUBLIC_URL}/Explore_03.jpg`}
                  alt=""
                />
              </div>
            </SwiperSlide>
            <SwiperSlide>
              <div className="gp__swiper-content">
                <img
                  className="gp__swiper-img"
                  src={`${process.env.PUBLIC_URL}/Explore_04.jpg`}
                  alt=""
                />
              </div>
            </SwiperSlide>
            <SwiperSlide>
              <div className="gp__swiper-content">
                <img
                  className="gp__swiper-img"
                  src={`${process.env.PUBLIC_URL}/Explore_05.jpg`}
                  alt=""
                />
              </div>
            </SwiperSlide>
          </Swiper>
          <div
            id="gp-button-next"
            className="swiper-button-next gallery-button"
          ></div>
        </section>
      </main>
      <Footer
        color="footer-black"
        logo="footer-logo-black"
        border="footer-border-black"
      />
    </>
  );
}

export default Gallary;
