import { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import gsap from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';
import { Pagination, Autoplay, EffectFade } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/autoplay';
import 'swiper/css/effect-fade';
import './style.scss';
import lightbox from './lightbox.svg';

function Pool() {
  // const [slideIndex, setSlideIndex] = useState(0);

  useEffect(() => {
    gsap.registerPlugin(ScrollTrigger);
    setAnimation1();
    setAnimation2();
    setAnimation3();
  }, []);

  const setAnimation1 = () => {
    gsap.fromTo(
      '.pool',
      { opacity: 0 },
      {
        opacity: 1,
        duration: 2,
        scrollTrigger: {
          trigger: '.pool',
          start: 'top center',
          end: 'bottom center',
        },
      }
    );
  };

  const setAnimation2 = () => {
    gsap.fromTo(
      '.pool__title',
      { opacity: 0 },
      {
        opacity: 1,
        duration: 2,
        delay: 0.6,
        scrollTrigger: {
          trigger: '.pool',
          start: 'top center',
          end: 'bottom center',
        },
      }
    );
  };

  const setAnimation3 = () => {
    gsap.fromTo(
      '.pool__text',
      { opacity: 0 },
      {
        opacity: 1,
        duration: 2,
        delay: 0.9,
        scrollTrigger: {
          trigger: '.pool',
          start: 'top center',
          end: 'bottom center',
        },
      }
    );
  };

  return (
    <>
      <div className="pool relative">
        <div className="pool__swiper">
          <div className="pool__inner">
            <h1 className="pool__title text-white font-serif uppercase leading-none">
              POOL
            </h1>
            <p className="pool__text text-white font-serif">
              全身を浮かべたらそっと目を閉じて。
              <br />
              風にそよぐパームツリーの葉音が聴こえる。
              <br />
              プールが主役になるとき。
            </p>
          </div>
          <div>
            <Swiper
              modules={[Pagination, Autoplay, EffectFade]}
              speed={4000}
              slidesPerGroup={1}
              slidesPerView={1}
              spaceBetween={0}
              autoplay={{
                delay: 0,
              }}
              loop={true}
              effect="fade"
              fadeEffect={{
                crossFade: true,
              }}
              allowTouchMove={false}
              // onSlideChange={(swiper) => {
              //   setSlideIndex(swiper.activeIndex);
              // }}
              pagination={{
                el: '#pool-swiper-pagination',
                renderBullet: (index, className) => {
                  return `<div class="pool__bar">
                            <div class="pool__index">
                                <p class="pool__index--text font-infant text-white">0${
                                  index + 1
                                }</p>
                            </div>
                            <div class="relative ${className}">
                                <div class="progress"></div>  
                                <div class="progressing"></div>
                            </div>
                          </div>`;
                },
              }}
            >
              <SwiperSlide>
                <div className="pool__content">
                  <div className="pool__item">
                    <img
                      className="pool__cover"
                      src={`${process.env.PUBLIC_URL}/Pool_Slider_01.jpg`}
                      alt="Pool_image_1"
                    />
                  </div>
                </div>
              </SwiperSlide>
              <SwiperSlide>
                <div className="pool__content">
                  <div className="pool__item">
                    <img
                      className="pool__cover"
                      src={`${process.env.PUBLIC_URL}/Pool_Slider_02.jpg`}
                      alt="Pool_image_2"
                    />
                  </div>
                </div>
              </SwiperSlide>
              <SwiperSlide>
                <div className="pool__content">
                  <div className="pool__item">
                    <img
                      className="pool__cover"
                      src={`${process.env.PUBLIC_URL}/Pool_Slider_03.jpg`}
                      alt="Pool_image_3"
                    />
                  </div>
                </div>
              </SwiperSlide>
              <SwiperSlide>
                <div className="pool__content">
                  <div className="pool__item">
                    <img
                      className="pool__cover"
                      src={`${process.env.PUBLIC_URL}/Pool_Slider_04.jpg`}
                      alt="Pool_image_4"
                    />
                  </div>
                </div>
              </SwiperSlide>
            </Swiper>
          </div>
        </div>

        <div className="pool__pagination">
          <div
            id="pool-swiper-pagination"
            className="swiper-pagination pagination mt-0"
          ></div>
        </div>
      </div>
      <div className="pool__bottom">
        <div className="pool__bottom--btn">
          <Link to="/gallery">
            <p className="all-scenes-text font-quicksand font-light text-white tracking-wider inline-block mr-2">
              ALL SCENES
            </p>
            <img className="pool__bottom--blocks" src={lightbox} />
          </Link>
        </div>
      </div>
    </>
  );
}

export default Pool;
