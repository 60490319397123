import Preloader from '../components/transition2';
import { Background } from '../components/Background';
import Menu from '../components/menu';
import Weather from '../components/weather';
import Mainvisual from '../components/mainvisual';
import Concept from '../components/concept';
import TeaserMovie from '../components/teasermovie';
import Pool from '../components/pool';
import Features from '../components/features';
import Gallery from '../components/gallery';
import Stories from '../components/stories';
import Newsletter from '../components/newsletter';
import Footer from '../components/footer';
import Cursor from '../components/Cursor';

function Home() {
  return (
    <>
      {/* <Preloader isTime={false} /> */}
      <Preloader />
      <Background />
      <Cursor isColor={false} />
      <Menu logo="menu-logo-white" background="menu-black" />
      <Weather isColor={false} />
      <Mainvisual />
      <TeaserMovie />
      <Concept />
      <Pool />
      <Features />
      <Gallery />
      <Stories />
      <Newsletter />
      <Footer />
    </>
  );
}

export default Home;
