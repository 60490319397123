import { useEffect } from 'react';
import { Link } from 'react-router-dom';
import { Navigation, Pagination } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';
import gsap from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import './style.scss';
import lightbox from './lightbox.svg';

function Gallery() {
  useEffect(() => {
    gsap.registerPlugin(ScrollTrigger);
    setAnimation1();
    setAnimation2();
    setAnimation3();
    setAnimation4();
    setAnimation5();
  }, []);

  const setAnimation1 = () => {
    gsap.fromTo(
      '.gallery__text',
      { opacity: 0 },
      {
        opacity: 1,
        duration: 2,
        scrollTrigger: {
          trigger: '.gallery',
          start: 'top center',
          end: 'bottom center',
          // onEnter: () => {
          //   console.log('scroll In')
          // },
          // onEnterBack: () => {
          //   console.log('scroll Back')
          // },
        },
      }
    );
  };

  const setAnimation2 = () => {
    gsap.fromTo(
      '.gallery__content1',
      { opacity: 0, y: 50 },
      {
        opacity: 1,
        y: 0,
        duration: 2,
        delay: 0.3,
        scrollTrigger: {
          trigger: '.gallery',
          start: 'top center',
          end: 'bottom center',
        },
      }
    );
  };

  const setAnimation3 = () => {
    gsap.fromTo(
      '.gallery__content2',
      { opacity: 0, y: 50 },
      {
        opacity: 1,
        y: 0,
        duration: 2,
        delay: 0.6,
        scrollTrigger: {
          trigger: '.gallery',
          start: 'top center',
          end: 'bottom center',
        },
      }
    );
  };

  const setAnimation4 = () => {
    gsap.fromTo(
      '.gallery__content3',
      { opacity: 0, y: 50 },
      {
        opacity: 1,
        y: 0,
        duration: 2,
        delay: 0.9,
        scrollTrigger: {
          trigger: '.gallery',
          start: 'top center',
          end: 'bottom center',
        },
      }
    );
  };

  const setAnimation5 = () => {
    gsap.fromTo(
      '.gallery__bottom',
      { opacity: 0, y: 20 },
      {
        opacity: 1,
        y: 0,
        duration: 2,
        delay: 1.2,
        scrollTrigger: {
          trigger: '.gallery',
          start: 'top center',
          end: 'bottom center',
        },
      }
    );
  };
  return (
    <>
      <div className="gallery">
        <div className="gallery__container">
          {/* <div className="gallery__side flex lg:flex-row flex-col">
            <div className="lg:w-6/12 w-full"></div>
            <div className="lg:w-6/12 w-full">
              <span className="gallery__side-text text-white font-serif font-semibold tracking-wider uppercase section-title vertical-rl">
                gallery
              </span>
            </div>
          </div> */}

          <div className="gallery__title">
            <h1 className="gallery__text text-white font-serif uppercase">
              gallery
            </h1>
          </div>

          <div className="gallery__contents">
            <div className="gallery__swiper">
              <Swiper
                modules={[Navigation, Pagination]}
                slidesPerGroup={1}
                slidesPerView={1.12}
                spaceBetween={0}
                loop={true}
                navigation={{
                  prevEl: '#gallery-button-prev',
                  nextEl: '#gallery-button-next',
                }}
                pagination={{
                  type: 'custom',
                  el: '#gallery-swiper-pagination',
                  renderCustom: function (Swiper, current, total) {
                    return (
                      '<span class="text-white swiper-num-text1 inline-block">' +
                      '0' +
                      current +
                      '</span>' +
                      '<span class="font-mincho gallery__slash text-block text-white"> /  </span>' +
                      '<span class="text-white swiper-num-text2 inline-block">' +
                      +'0' +
                      total +
                      '</span>'
                    );
                  },
                }}
                breakpoints={{
                  1024: {
                    slidesPerGroup: 3,
                    slidesPerView: 3,
                  },
                  640: {
                    slidesPerView: 2.3,
                  },
                }}
              >
                <SwiperSlide>
                  <div className="gallery__content gallery__content2">
                    <div className="gallery__item">
                      <img
                        className="gallery__img"
                        src={`${process.env.PUBLIC_URL}/Gallery_Slider_02.jpg`}
                      />
                    </div>
                  </div>
                </SwiperSlide>
                <SwiperSlide>
                  <div className="gallery__content gallery__content3">
                    <div className="gallery__item">
                      <img
                        className="gallery__img"
                        src={`${process.env.PUBLIC_URL}/Gallery_Slider_03.jpg`}
                      />
                    </div>
                  </div>
                </SwiperSlide>
                <SwiperSlide>
                  <div className="gallery__content gallery__content3">
                    <div className="gallery__item">
                      <img
                        className="gallery__img"
                        src={`${process.env.PUBLIC_URL}/Gallery_Slider_04.jpg`}
                      />
                    </div>
                  </div>
                </SwiperSlide>
                <SwiperSlide>
                  <div className="gallery__content gallery__content3">
                    <div className="gallery__item">
                      <img
                        className="gallery__img"
                        src={`${process.env.PUBLIC_URL}/Gallery_Slider_05.jpg`}
                      />
                    </div>
                  </div>
                </SwiperSlide>
                <SwiperSlide>
                  <div className="gallery__content gallery__content3">
                    <div className="gallery__item">
                      <img
                        className="gallery__img"
                        src={`${process.env.PUBLIC_URL}/Gallery_Slider_06.jpg`}
                      />
                    </div>
                  </div>
                </SwiperSlide>
                <SwiperSlide>
                  <div className="gallery__content gallery__content3">
                    <div className="gallery__item">
                      <img
                        className="gallery__img"
                        src={`${process.env.PUBLIC_URL}/Gallery_Slider_07.jpg`}
                      />
                    </div>
                  </div>
                </SwiperSlide>
                <SwiperSlide>
                  <div className="gallery__content gallery__content3">
                    <div className="gallery__item">
                      <img
                        className="gallery__img"
                        src={`${process.env.PUBLIC_URL}/Gallery_Slider_08.jpg`}
                      />
                    </div>
                  </div>
                </SwiperSlide>
                <SwiperSlide>
                  <div className="gallery__content gallery__content3">
                    <div className="gallery__item">
                      <img
                        className="gallery__img"
                        src={`${process.env.PUBLIC_URL}/Gallery_Slider_09.jpg`}
                      />
                    </div>
                  </div>
                </SwiperSlide>
                <SwiperSlide>
                  <div className="gallery__content gallery__content1">
                    <div className="gallery__item">
                      <img
                        className="gallery__img"
                        src={`${process.env.PUBLIC_URL}/Gallery_Slider_01.jpg`}
                      />
                    </div>
                  </div>
                </SwiperSlide>
              </Swiper>
            </div>

            <div className="gallery__bottom">
              <div className="w-4/12 pc-only"></div>
              <div className="w-4/12 flex flex-col justify-center align-center pc-only">
                <div className="gallery__control relative h-full flex flex-row justify-center items-center h-fit lg:text-center">
                  <div>
                    <div
                      id="gallery-button-prev"
                      className="swiper-button-prev gallery-button"
                    ></div>
                  </div>
                  <div id="gallery-swiper-pagination"></div>
                  <div>
                    <div
                      id="gallery-button-next"
                      className="swiper-button-next gallery-button"
                    ></div>
                  </div>
                </div>
              </div>

              <div className="gallery__btn-wrap lg:w-4/12">
                <div className="w-full h-full flex justify-end items-end">
                  <div className="gallery__btn">
                    <Link className="gallery__underline" to="/gallery">
                      <p className="all-scenes-text font-quicksand font-light text-white tracking-wider inline-block mr-2">
                        ALL SCENES
                      </p>
                      <img className="pool__bottom--blocks" src={lightbox} />
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Gallery;
