import { useEffect, useRef } from 'react';
import { ReactComponent as BPCIcon } from './logo-bpc-h.svg';
import { ReactComponent as LightboxIcon } from './lightbox.svg';
import { Link, useLocation } from 'react-router-dom';
import gsap from 'gsap';
import ScrollTrigger from 'gsap/ScrollTrigger';
import './style.scss';

function Menu(props) {
  const galleryEl = useRef();
  const ctaEl = useRef();
  const ctaTextEl = useRef();

  const location = useLocation();

  useEffect(() => {
    const path = location.pathname;
    let triggerEl;
    let startPosition;

    if (path === '/') {
      triggerEl = '.concept';
    } else if (path === '/contact') {
      triggerEl = '.contact__content';
    } else if (path === '/mediakit') {
      triggerEl = '.mediakit__content';
    }

    if (path === '/') {
      ctaTextEl.current.style.color = '#f6f4f1';
      startPosition = 'top center';
    } else {
      ctaTextEl.current.style.color = '#2A302E';
      startPosition = 'top top';
    }

    if (path === '/' && window.innerWidth >= 640) {
      ctaEl.current.style.borderBottom = '1px solid #f6f4f1';
    } else if (window.innerWidth >= 640) {
      ctaEl.current.style.borderBottom = '1px solid #2A302E';
    }

    if (location.pathname === '/gallery') {
      galleryEl.current.style.display = 'none';
    } else {
      gsap.registerPlugin(ScrollTrigger);
      gsap.fromTo(
        ctaEl.current,
        {
          opacity: 0,
        },
        {
          opacity: 1,
          ease: 'Power0.easeOut',
          duration: 1.5,
          scrollTrigger: {
            trigger: triggerEl,
            start: startPosition,
          },
        }
      );
    }
  });

  return (
    <>
      <div className={`menu ${props.background}`}>
        <div className="menu__container">
          <div>
            <Link className="menu__btn inline-block" to="/">
              <BPCIcon
                className={`menu__logo ${props.logo}`}
                alt="Botanical Pool Club LOGO"
              />
            </Link>
          </div>

          <div ref={galleryEl}>
            <Link to="/gallery">
              <div className="menu__gallery" ref={ctaEl}>
                <div>
                  <p className="menu__gallery--text" ref={ctaTextEl}>
                    VIEW GALLERY
                  </p>
                </div>
                <div>
                  <LightboxIcon className={`menu__lightbox ${props.logo}`} />
                </div>
              </div>
            </Link>
          </div>
        </div>
      </div>
    </>
  );
}

export default Menu;
