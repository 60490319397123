import './style.scss';

function Background() {
  return (
    <>
      <div className="background">
        <div className="background__vid-wrap">
          <video playsInline autoPlay loop muted className="background__video">
            <source
              src="https://cdn.botanicalpoolclub.com/files/01/videos/bg_BPC_black_Final.mp4"
              type="video/mp4"
            />
          </video>
        </div>
        <div className="background__c"></div>
      </div>
    </>
  );
}

function BackgroundWhite() {
  return (
    <>
      <div className="white-background"></div>
    </>
  );
}

export { Background, BackgroundWhite };
