import React, { useState, useEffect, useRef } from 'react';
import './style.scss';

function Cursor({ isColor }) {
  const [mouseX, setMouseX] = useState(false);
  const [mouseY, setMouseY] = useState(false);

  const [isDisplay, setIsDisplay] = useState(false);
  const [isSize, setIsSize] = useState(null);
  const [isPosition, setIsPosition] = useState(null);

  const [isText, setIsText] = useState(null);

  const cursorRef = useRef();
  const textEl = useRef();

  useEffect(() => {
    const mouseMoveListener = (e) => {
      setMouseX(e.pageX);
      setMouseY(e.pageY);
    };

    document.addEventListener('mousemove', mouseMoveListener);

    return () => {
      document.removeEventListener('mousemove', mouseMoveListener);
    };
  }, []);

  useEffect(() => {
    const mouseEnterListener = (e) => {
      setTimeout(() => {
        setIsDisplay(true);
        setIsSize(null);
        setIsPosition(null);
        cursorRef.current.style.opacity = 0;
      }, 100);
      setTimeout(() => {
        setIsSize(14);
        setIsPosition(-7);
        cursorRef.current.style.opacity = 1;
      }, 300);
      setTimeout(() => {
        setIsSize(12);
        setIsPosition(-6);
        setIsText('PLAY THE VIDEO');
      }, 500);
      // console.log("enterした");
    };

    const mouseVidEnterListener = (e) => {
      if (isSize === 1.5) {
        setIsSize(12);
      } else {
        setIsSize(1.5);
      }
      if (isPosition === -0.75) {
        setIsPosition(-6);
      } else {
        setIsPosition(-0.75);
      }
      if (isText === null) {
        setTimeout(() => {
          setIsText('PLAY THE VIDEO');
        }, 100);
      } else {
        setIsText(null);
      }
    };

    const mouseLeaveListener = (e) => {
      setTimeout(() => {
        setIsSize(0);
        setIsPosition(0);
        setIsText(null);
      }, 200);
      setTimeout(() => {
        setIsDisplay(false);
        setIsSize(null);
        setIsPosition(null);
        setIsText(null);
      }, 300);
      // console.log("leaveした");
    };

    if (document.querySelector('.movie-mouse') != null) {
      document
        .querySelector('.movie-mouse')
        .addEventListener('mouseenter', mouseEnterListener);
      document
        .querySelector('.movie-mouse')
        .addEventListener('mouseleave', mouseLeaveListener);
      document
        .querySelector('.movie-mouse')
        .addEventListener('click', mouseVidEnterListener);
      // document.querySelector(".movie-mouse").addEventListener("mouseleave", mouseLeaveListener)
    }

    return () => {
      if (document.querySelector('.movie-mouse') != null) {
        document
          .querySelector('.movie-mouse')
          .removeEventListener('mouseenter', mouseEnterListener);
        document
          .querySelector('.movie-mouse')
          .removeEventListener('mouseleave', mouseLeaveListener);
        document
          .querySelector('.movie-mouse')
          .removeEventListener('click', mouseVidEnterListener);
      }
    };
  }, [isSize, isPosition, isText]);

  return (
    <>
      <div
        className="cursor"
        style={{
          transform: `translate(${mouseX}px, ${mouseY}px)`,
          width: `${isSize}vw`,
          height: `${isSize}vw`,
          top: `${isPosition}vw`,
          left: `${isPosition}vw`,
          display: isDisplay ? 'flex' : 'none',
          color: isColor ? '#2A302E' : '#f6f4f1',
          border: isColor ? '1px solid #2A302E' : '1px solid #f6f4f1',
        }}
        ref={cursorRef}
      >
        <p className="cursor__text font-serif" ref={textEl}>
          {isText}
        </p>
      </div>
    </>
  );
}

export default Cursor;
