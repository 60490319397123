import React from 'react';
import Transition from '../../components/transition';
import { BackgroundWhite } from '../../components/Background';
import Cursor from '../../components/Cursor';
import Menu from '../../components/menu';
import Weather from '../../components/weather';
import Footer from '../../components/footer';
import { ReactComponent as DownloadIcon } from './download.svg';
import './style.scss';

function Mediakit() {
  document.title = 'MEDIAKIT | BOTANICAL POOL CLUB | OFFICIAL TEASER SITE';
  let dlText = 'DOWNLOAD';

  return (
    <>
      <Transition />
      <BackgroundWhite />
      <Cursor isColor />
      <Menu logo="menu-logo-black" background="menu-white" />
      <Weather isColor />
      <main className="mediakit">
        <div className="mediakit__content">
          <h1 className="mediakit__title font-serif uppercase">
            MEDIA&nbsp;KIT
          </h1>
          <p className="mediakit__message font-serif">
            BOTANICAL POOL
            CLUBに関するブランドガイドライン、ロゴ素材をダウンロードできます。メディアやSNS等でBOTANICAL
            POOL CLUBについて掲載する際にご利用下さい。
            <br />
            <br />
            -利用規約-
            <br />
            株式会社VALMが所有するすべての画像およびその権利は、利用者によって商業的な目的で使用されないことが理解され、同意されたものと見なされます。利用者が当ウェブサイトからダウンロードされた素材を使用する場合は、自己責任で行ってください。株式会社VALMの同意を得ずに、上記の利用範囲を超える場合は事前に許可を取得する必要があります。
          </p>

          <section className="mediakit__box">
            <div className="mediakit__inner">
              <div>
                <h2 className="mediakit__subtitle font-serif">
                  Brand&nbsp;Guidelines
                </h2>
                <p className="mediakit__text font-serif">
                  BOTANICAL POOL
                  CLUBのブランドガイドラインをダウンロードできます。
                </p>
              </div>
              <div>
                <a
                  className="font-serif mediakit__button"
                  href="https://cdn.botanicalpoolclub.com/files/01/pdfs/BPC_BrandGuidelines.pdf"
                  target="_blank"
                  download="BPC_BrandGuidelines.pdf"
                >
                  <div className="mediakit__df">
                    <div>
                      <DownloadIcon className="mediakit__icon" />
                    </div>
                    <div>{dlText}</div>
                  </div>
                </a>
              </div>
            </div>
            <div className="mediakit__inner">
              <div className="mediakit__placeholder mediakit__bgl"></div>
            </div>
          </section>

          <section className="mediakit__box">
            <div className="mediakit__inner">
              <div>
                <h2 className="mediakit__subtitle font-serif">
                  Logo&nbsp;Pack
                </h2>
                <p className="mediakit__text font-serif">
                  BOTANICAL POOL
                  CLUBのロゴ素材をダウンロードできます。※ダウンロード・ご利用については「ブランドガイドライン」に準拠して行われる必要があります。
                </p>
              </div>
              <div>
                <a
                  className="font-serif mediakit__button"
                  href="https://cdn.botanicalpoolclub.com/files/01/assets/BPC_logoPack.zip"
                  download="BPC_logoPack.zip"
                >
                  <div className="mediakit__df">
                    <div>
                      <DownloadIcon className="mediakit__icon" />
                    </div>
                    <div>{dlText}</div>
                  </div>
                </a>
              </div>
            </div>
            <div className="mediakit__inner">
              <div className="mediakit__placeholder mediakit__lp"></div>
            </div>
          </section>

          <section className="mediakit__box">
            <div className="mediakit__inner">
              <div>
                <h2 className="mediakit__subtitle font-serif">
                  Brand&nbsp;Visual&nbsp;Assets
                </h2>
                <p className="mediakit__text font-serif">
                  BOTANICAL POOL CLUBの写真素材をダウンロードできます。
                </p>
              </div>
              <div>
                <a
                  className="font-serif mediakit__button"
                  href="https://cdn.botanicalpoolclub.com/files/01/assets/Brand_Visual_Assets.zip"
                  download="Brand_Visual_Assets.zip"
                >
                  <div className="mediakit__df">
                    <div>
                      <DownloadIcon className="mediakit__icon" />
                    </div>
                    <div>{dlText}</div>
                  </div>
                </a>
              </div>
            </div>
            <div className="mediakit__inner">
              <div className="mediakit__placeholder mediakit__va"></div>
            </div>
          </section>
        </div>
      </main>
      <Footer
        color="footer-black"
        logo="footer-logo-black"
        border="footer-border-black"
      />
    </>
  );
}

export default Mediakit;
