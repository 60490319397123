import { useState, useEffect, useRef } from 'react';
import { Pagination } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';
import gsap from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import './style.scss';
import playbutton from '../../play-button.svg';

function Stories() {
  const [videoPlayer, setVideoPlayer] = useState(false);
  const overlayEl = useRef();
  const popupEl = useRef();
  const video1El = useRef();

  function clickFunc1() {
    video1El.current.load();
    video1El.current.play();
    setVideoPlayer(true);
  }

  function disableScroll(e) {
    e.preventDefault();
  }

  useEffect(() => {
    gsap.registerPlugin(ScrollTrigger);
    setAnimation1();
    setAnimation2();
    setAnimation3();
    setAnimation4();
    setAnimation5();

    const escapeAction = (e) => {
      video1El.current.pause();
      setVideoPlayer(false);
    };

    if (overlayEl.current != null) {
      document.addEventListener('keydown', escapeAction);
      overlayEl.current.addEventListener('click', escapeAction);
      popupEl.current.addEventListener('click', escapeAction);
    }

    if (videoPlayer) {
      document.addEventListener('touchmove', disableScroll, {
        passive: false,
      });
      document.addEventListener('mousewheel', disableScroll, {
        passive: false,
      });
    }

    return () => {
      document.removeEventListener('touchmove', disableScroll, {
        passive: false,
      });
      document.removeEventListener('mousewheel', disableScroll, {
        passive: false,
      });
      if (overlayEl.current != null) {
        document.removeEventListener('keydown', escapeAction);
        overlayEl.current.addEventListener('click', escapeAction);
        popupEl.current.addEventListener('click', escapeAction);
      }
    };
  }, []);

  const setAnimation1 = () => {
    gsap.fromTo(
      '.stories__title',
      { opacity: 0 },
      {
        opacity: 1,
        duration: 2,
        scrollTrigger: {
          trigger: '.stories',
          start: 'top center',
          end: 'bottom center',
        },
      }
    );
  };

  const setAnimation2 = () => {
    gsap.fromTo(
      '.stories__content1',
      { opacity: 0, y: 50 },
      {
        opacity: 1,
        y: 0,
        duration: 2,
        delay: 0.3,
        scrollTrigger: {
          trigger: '.stories',
          start: 'top center',
          end: 'bottom center',
        },
      }
    );
  };

  const setAnimation3 = () => {
    gsap.fromTo(
      '.stories__content2',
      { opacity: 0, y: 50 },
      {
        opacity: 1,
        y: 0,
        duration: 2,
        delay: 0.6,
        scrollTrigger: {
          trigger: '.stories',
          start: 'top center',
          end: 'bottom center',
        },
      }
    );
  };

  const setAnimation4 = () => {
    gsap.fromTo(
      '.stories__content3',
      { opacity: 0, y: 50 },
      {
        opacity: 1,
        y: 0,
        duration: 2,
        delay: 0.9,
        scrollTrigger: {
          trigger: '.stories',
          start: 'top center',
          end: 'bottom center',
        },
      }
    );
  };

  const setAnimation5 = () => {
    gsap.fromTo(
      '.stories__content4',
      { opacity: 0, y: 50 },
      {
        opacity: 1,
        y: 0,
        duration: 2,
        delay: 1.2,
        scrollTrigger: {
          trigger: '.stories',
          start: 'top center',
          end: 'bottom center',
        },
      }
    );
  };
  return (
    <>
      <div className="stories">
        <div className="stories__container flex lg:flex-row flex-col">
          <div className="stories__side flex lg:flex-row flex-col"></div>

          <div className="stories__contents">
            <h1 className="stories__title sc-title text-white font-serif">
              BPC Stories
            </h1>

            <div className="flex lg:flex-row flex-col justify-center">
              <div className="stories__swiper flex flex-col">
                <div>
                  <Swiper
                    // modules={[Pagination]}
                    slidesPerGroup={1}
                    slidesPerView={1.12}
                    spaceBetween={0}
                    loop={true}
                    // pagination={{
                    //   type: 'progressbar',
                    //   el: '#stories-swiper-pagination',
                    // }}
                    breakpoints={{
                      1024: {
                        slidesPerView: 4,
                      },
                      640: {
                        slidesPerView: 2.3,
                      },
                    }}
                  >
                    <SwiperSlide>
                      <div
                        className="stories__content stories__content1"
                        onClick={clickFunc1}
                      >
                        <div className="stories__text">
                          <div>
                            <img
                              className="stories__play"
                              src={playbutton}
                              alt="Play Button"
                            />
                          </div>
                          <div>
                            <p className="font-serif caption text-white tracking-loose">
                              Play the story
                            </p>
                          </div>
                        </div>
                        <img
                          className="stories__cover"
                          src={`${process.env.PUBLIC_URL}/Stories_01.jpg`}
                        />
                      </div>
                    </SwiperSlide>
                    <SwiperSlide>
                      <div className="stories__content stories__content2">
                        <div className="stories__item">
                          <div className="stories__overlay"></div>
                          <div className="stories__text">
                            <div>
                              <p className="font-serif caption text-white tracking-loose">
                                Coming Soon
                              </p>
                            </div>
                          </div>
                          <img
                            className="stories__cover"
                            src={`${process.env.PUBLIC_URL}/Stories_02.jpg`}
                          />
                        </div>
                      </div>
                    </SwiperSlide>
                    <SwiperSlide>
                      <div className="stories__content stories__content3">
                        <div className="stories__item">
                          <div className="stories__overlay"></div>
                          <div className="stories__text">
                            <div>
                              <p className="font-serif caption text-white tracking-loose">
                                Coming Soon
                              </p>
                            </div>
                          </div>
                          <img
                            className="stories__cover"
                            src={`${process.env.PUBLIC_URL}/Stories_03.jpg`}
                          />
                        </div>
                      </div>
                    </SwiperSlide>
                    <SwiperSlide>
                      <div className="stories__content stories__content4">
                        <div className="stories__item">
                          <div className="stories__overlay"></div>
                          <div className="stories__text">
                            <div>
                              <p className="font-serif caption text-white tracking-loose">
                                Coming Soon
                              </p>
                            </div>
                          </div>
                          <img
                            className="stories__cover"
                            src={`${process.env.PUBLIC_URL}/Stories_04.jpg`}
                          />
                        </div>
                      </div>
                    </SwiperSlide>
                  </Swiper>
                </div>
                {/* <div className="stories__progressbar flex flex-col lg:mt-7 mt-10">
                  <span className="text-white font-serif tracking-wider pc-only">
                    Do Try Scroll
                  </span>
                  <div
                    id="stories-swiper-pagination"
                    className="swiper-pagination pagination mt-2.5"
                  ></div>
                </div> */}
              </div>
            </div>
          </div>
        </div>

        <div
          className="stories__layer"
          style={{ display: videoPlayer ? 'block' : 'none' }}
          ref={overlayEl}
        ></div>
        <div
          className="stories__popup"
          style={{ display: videoPlayer ? 'flex' : 'none' }}
          ref={popupEl}
        >
          <div>
            <video
              class="stories__player"
              src="https://cdn.botanicalpoolclub.com/files/01/videos/230423_01_BPC_POOLSIDE.mp4"
              ref={video1El}
            ></video>
          </div>
        </div>
      </div>
    </>
  );
}

export default Stories;
