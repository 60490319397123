import { useEffect, useRef, useState } from 'react';
import gsap from 'gsap';
import { ReactComponent as BPCIcon } from './logo-bpc-h.svg';
import './style.scss';

function Transition() {
  const [loading, setLoading] = useState(true);

  const logoEl = useRef();
  const transitionEl = useRef();

  useEffect(() => {
    gsap.to(transitionEl.current, {
      opacity: 1,
      duration: 1,
    });
    gsap.fromTo(
      logoEl.current,
      {
        opacity: 0,
      },
      {
        opacity: 1,
        duration: 1,
      }
    );
    const endAnimation1 = setTimeout(() => {
      gsap.to(logoEl.current, {
        opacity: 0,
        duration: 1,
      });
    }, 1000);
    const endAnimation2 = setTimeout(() => {
      gsap.to(transitionEl.current, {
        opacity: 0,
        duration: 0.3,
        ease: 'Power1.easeInOut',
      });
    }, 2000);
    const endAnimation4 = setTimeout(() => {
      setLoading(false);
      document.removeEventListener('touchmove', handle, { passive: false });
      document.removeEventListener('mousewheel', handle, { passive: false });
    }, 3000);

    function handle(e) {
      e.preventDefault();
    }

    if (loading) {
      document.addEventListener('touchmove', handle, { passive: false });
      document.addEventListener('mousewheel', handle, { passive: false });
    } else {
      document.removeEventListener('touchmove', handle, { passive: false });
      document.removeEventListener('mousewheel', handle, { passive: false });
    }

    return () => {
      clearInterval(endAnimation1);
      clearInterval(endAnimation2);
      document.removeEventListener('touchmove', handle, { passive: false });
      document.removeEventListener('mousewheel', handle, { passive: false });
    };
  }, [loading]);
  return (
    <>
      <section
        className="transition"
        style={{ display: loading ? 'block' : 'none' }}
        ref={transitionEl}
      >
        <div className="transition-flex">
          <BPCIcon ref={logoEl} className="transition-image" />
        </div>
      </section>
    </>
  );
}

export default Transition;
