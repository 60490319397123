import { Link } from 'react-router-dom';
import { ReactComponent as BPCIcon } from '../../logo-bpc.svg';
import './style.scss';
import { ReactComponent as InstagramIcon } from './logo-instagram.svg';
import { ReactComponent as PinterestIcon } from './logo-pinterest.svg';

function Footer(props) {
  return (
    <>
      <section className="footer w-screen">
        <div className="footer__container">
          <div className={`footer__border ${props.border}`}>
            <div className="footer__inner">
              <div className="footer__logo-area">
                <div>
                  <Link to="/">
                    <BPCIcon className={`footer__logo ${props.logo}`} />
                  </Link>
                </div>
              </div>

              <div className="footer__links">
                <div className="footer__flex">
                  <div className="footer__margin">
                    <Link
                      className={`text-white ${props.color} font-serif small-text lg:text-left text-center`}
                      to="/gallery"
                    >
                      GALLERY
                    </Link>
                  </div>

                  <div className="footer__margin">
                    <a
                      className={`text-white ${props.color} font-serif small-text lg:text-left text-center`}
                      href="https://valm.co.jp/recruit/bpc"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      RECRUIT
                    </a>
                  </div>
                </div>

                <div className="footer__flex">
                  <div className="footer__margin">
                    <Link
                      className={`text-white ${props.color} font-serif small-text lg:text-left text-center`}
                      to="/mediakit"
                    >
                      MEDIA KIT
                    </Link>
                  </div>

                  <div className="footer__margin">
                    <Link
                      className={`text-white ${props.color} font-serif small-text lg:text-left text-center`}
                      to="/contact"
                    >
                      CONTACT
                    </Link>
                  </div>
                </div>

                <div className="footer__flex">
                  <div className="footer__margin">
                    <a
                      className={`text-white ${props.color} font-serif small-text lg:text-left text-center`}
                      href="https://valm.co.jp/aboutus"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      COMPANY OVERVIEW
                    </a>
                  </div>

                  <div className="footer__margin">
                    <a
                      className={`text-white ${props.color} font-serif small-text lg:text-left text-center`}
                      href="https://valm.co.jp/privacy"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      PRIVACY POLICY
                    </a>
                  </div>
                </div>

                <div className="footer__sns">
                  <div className="footer__btn">
                    <a
                      href="https://www.instagram.com/botanicalpoolclub/"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <InstagramIcon className={`footer__icon ${props.logo}`} />
                    </a>
                  </div>
                  <div className="footer__btn">
                    <a
                      href="https://www.pinterest.com/BOTANICALPOOLCLUB/"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <PinterestIcon className={`footer__icon ${props.logo}`} />
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="footer__copy">
            <div>
              <p
                className={`text-white ${props.color} font-serif small-text lg:text-left text-center`}
              >
                Copyrights &copy; VALM Inc. | All Rights Reserved.
              </p>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default Footer;
