import { BackgroundWhite } from '../../components/Background';
import Cursor from '../../components/Cursor';
import Menu from '../../components/menu';
import Weather from '../../components/weather';
import './style.scss';
import { useLocation } from 'react-router-dom';
import { useLayoutEffect, useState } from 'react';

function Thanks() {
  const [display, setDisplay] = useState(true);
  const location = useLocation();
  document.title = 'THANKS | BOTANICAL POOL CLUB | OFFICIAL TEASER SITE';

  useLayoutEffect(() => {
    if (location.pathname === '/contact/thanks') {
      setDisplay(true);
    } else {
      setDisplay(false);
    }
  });

  return (
    <>
      <Cursor isColor />
      <Menu logo="menu-logo-black" background="menu-white" />
      <Weather isColor />
      <main className="thanks">
        <BackgroundWhite />
        <section className="thanks__container">
          <div>
            <h1 className="font-serif">Thank you</h1>
            <p
              className="font-serif"
              style={{ display: display ? 'block' : 'none' }}
            >
              この度はお問い合わせいただき、誠にありがとうございました。
              <br />
              お送り頂いたフォームは無事送信されました。
            </p>
            <p
              className="font-serif"
              style={{ display: display ? 'none' : 'block' }}
            >
              ニュースレターのご登録ありがとうございます。
            </p>
          </div>
        </section>
      </main>
    </>
  );
}

export default Thanks;
