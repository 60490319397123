import axios from 'axios';
import { useEffect, useState, useRef } from 'react';
import { ReactComponent as PoolIcon } from './temp.svg';
import './style.scss';

function Weather(props) {
  const [display, setDisplay] = useState(true);
  const [icon, setIcon] = useState(true);
  const targetEl = useRef();

  const isColor = props.isColor;

  const baseURL1 =
    'https://api.openweathermap.org/data/2.5/weather?q=Kimitsu,JP&appid=5ceb16b65e7ba82776e36174096cf8c0&lang=en&units=metric';

  const [post, setPost] = useState(null);

  useEffect(() => {
    if (isColor) {
      setIcon(true);
    } else {
      setIcon(false);
    }

    const scrollEventListener = (e) => {
      const scrollTop =
        window.pageYOffset || document.documentElement.scrollTop;

      let position = 100;

      if (position < scrollTop) {
        setTimeout(() => {
          targetEl.current.style.opacity = 0;
        }, 100);
        setTimeout(() => {
          setDisplay(false);
        }, 600);
      }
    };

    window.addEventListener('scroll', scrollEventListener);

    return () => {
      window.removeEventListener('scroll', scrollEventListener);
    };
  }, [display]);

  useEffect(() => {
    axios.get(baseURL1).then((Response) => {
      setPost(Response.data);
    });
  }, []);

  if (!post) return null;

  const { weather, main } = post;

  return (
    <>
      <div
        className="weather"
        ref={targetEl}
        style={{ display: display ? 'block' : 'none' }}
      >
        <div className="weather__container">
          <div className="weather__inner">
            <div style={{ display: icon ? 'none' : 'block' }}>
              <img
                className="weather__icon"
                src={`https://cdn.yumoriya.com/files/02/images/owmiw/${weather[0].icon}.png`}
                alt={weather[0].description}
              />
            </div>
            <div style={{ display: icon ? 'block' : 'none' }}>
              <img
                className="weather__icon"
                src={`https://cdn.yumoriya.com/files/02/images/owmib/${weather[0].icon}.png`}
                alt={weather[0].description}
              />
            </div>
            <div>
              <p
                className={`weather__text font-mincho`}
                style={{ color: isColor ? '#2A302E' : '#fff' }}
              >
                {main.temp.toFixed(1)}
                <span className="weather__mark">&#8451;</span>
              </p>
            </div>
          </div>

          <div className="weather__inner">
            <div>
              {/* <img
                className="weather__icon"
                style={{ display: icon ? 'none' : 'block' }}
                src={poolTemperature}
                alt={weather[0].description}
              /> */}
              <PoolIcon
                className="weather__pool weather-icon-white"
                style={{ display: icon ? 'none' : 'block' }}
              ></PoolIcon>
              <PoolIcon
                className="weather__pool weather-icon-black"
                style={{ display: icon ? 'block' : 'none' }}
              ></PoolIcon>
            </div>
            <div>
              <p
                className={`weather__text font-mincho`}
                style={{ color: isColor ? '#2A302E' : '#fff' }}
              >
                35.0
                <span className="weather__mark">&#8451;</span>
              </p>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Weather;
