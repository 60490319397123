import { useState, useLayoutEffect, useRef } from 'react';
import gsap from 'gsap';
import { Pagination, Autoplay, EffectFade } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/autoplay';
import 'swiper/css/effect-fade';
import './style.scss';
import instagram from './logo-instagram.svg';
import pinterest from './logo-pinterest.svg';
import copy from './copy.svg';
import volume from './volume.svg';
import dimensions from './dimensions.svg';

function Mainvisual() {
  const [location, setLocation] = useState('E 139° 50’ 40.5”  N 35° 06’ 29.2”');
  const locationEl = useRef();
  const copyEl = useRef();
  const infoEl = useRef();
  const msgEl = useRef();
  const swiperEl = useRef();

  const clickHandler = () => {
    navigator.clipboard.writeText(location);
    setTimeout(() => {
      setLocation('Location Copied');
      copyEl.current.style.opacity = 0;
    }, 300);
    setTimeout(() => {
      locationEl.current.classList.remove('mv-show');
    }, 1000);
    setTimeout(() => {
      setLocation('E 139° 50’ 40.5”  N 35° 06’ 29.2”');
      locationEl.current.classList.add('mv-show');
      gsap.to(copyEl.current, {
        opacity: 1,
        duration: 1.75,
      });
    }, 2500);
  };

  useLayoutEffect(() => {
    setTimeout(() => {
      swiperEl.current.swiper.autoplay.stop();
    }, 0);
    setTimeout(() => {
      swiperEl.current.swiper.slideTo(0);
    }, 0);
    setTimeout(() => {
      swiperEl.current.swiper.autoplay.start();
    }, 4500);
    setTimeout(() => {
      locationEl.current.classList.add('mv-show');
    }, 6000);
    setTimeout(() => {
      infoEl.current.classList.add('mv-show');
    }, 7000);
    setTimeout(() => {
      msgEl.current.classList.add('mv-show');
    }, 8000);
  });

  useLayoutEffect(() => {
    let facilities = ['pool', 'hotel', 'room', 'botanical'];

    // for (let i = 0; i < facilities.length; i++) {
    // }
    document.querySelector('.target__0').innerHTML = facilities[0];
    document.querySelector('.target__1').innerHTML = facilities[1];
    document.querySelector('.target__2').innerHTML = facilities[2];
    document.querySelector('.target__3').innerHTML = facilities[3];
  });

  return (
    <>
      <div className="mv">
        <div className="mv__bg">
          <Swiper
            ref={swiperEl}
            modules={[Pagination, Autoplay, EffectFade]}
            speed={4000}
            slidesPerGroup={1}
            slidesPerView={1}
            spaceBetween={0}
            autoplay={{
              delay: 0,
            }}
            loop={true}
            effect="fade"
            fadeEffect={{
              crossFade: true,
            }}
            allowTouchMove={false}
            // onSlideChange={(swiper) => {
            //   setSlideIndex(swiper.activeIndex);
            // }}
            pagination={{
              el: '#mv-swiper-pagination',
              renderBullet: (index, className) => {
                return `<div class="mv__bar">
                            <div class="mv__index">
                                <p class="mv__index--text font-infant text-white">0${
                                  index + 1
                                }</p>
                            </div>
                            <div class="relative ${className}">
                                <div class="progress"></div>  
                                <div class="progressing"></div>
                            </div>
                            <div class="mv__index">
                                <p class="mv__index--text mv__title font-infant text-white uppercase target__${index}"><p>
                            </div>
                          </div>`;
              },
            }}
          >
            <SwiperSlide>
              <div className="mv__scontent">
                <div className="mv__item">
                  <img
                    className="mv__cover"
                    src={`${process.env.PUBLIC_URL}/kv_pool.png`}
                    alt="Pool_image_1"
                  />
                </div>
              </div>
            </SwiperSlide>
            <SwiperSlide>
              <div className="mv__scontent">
                <div className="mv__item">
                  <img
                    className="mv__cover"
                    src={`${process.env.PUBLIC_URL}/kv_hotel.png`}
                    alt="Pool_image_2"
                  />
                </div>
              </div>
            </SwiperSlide>
            <SwiperSlide>
              <div className="mv__scontent">
                <div className="mv__item">
                  <img
                    className="mv__cover mv__bed"
                    src={`${process.env.PUBLIC_URL}/kv_room.png`}
                    alt="Pool_image_3"
                  />
                </div>
              </div>
            </SwiperSlide>
            <SwiperSlide>
              <div className="mv__scontent">
                <div className="mv__item">
                  <img
                    className="mv__cover"
                    src={`${process.env.PUBLIC_URL}/kv_botanical.png`}
                    alt="Pool_image_4"
                  />
                </div>
              </div>
            </SwiperSlide>
          </Swiper>
        </div>

        <div className="mv__flex">
          <div className="mv__side"></div>

          <div className="mv__sns">
            <ul>
              <li className="mb-5">
                <a
                  href="https://www.instagram.com/botanicalpoolclub/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <img className="mv__icon" src={instagram} />
                </a>
              </li>
              <li>
                <a
                  href="https://www.pinterest.com/BOTANICALPOOLCLUB/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <img className="mv__icon" src={pinterest} />
                </a>
              </li>
            </ul>
          </div>

          <div className="mv__container">
            <div className="mv__place" onClick={clickHandler} ref={locationEl}>
              <h1 className="mv__place--text text-white">{location}</h1>
              <img
                className="mv__place--icon"
                ref={copyEl}
                src={copy}
                alt="copy icon"
              />
            </div>
            <div className="mv__info" ref={infoEl}>
              <div className="mv__content">
                <div className="mv__row">
                  <div>
                    <img className="mv__i" src={volume} alt="volume icon" />
                  </div>
                  <div>
                    <span className="mv__value">540,000</span>
                    <span className="mv__unit text-white">&#8467;</span>
                  </div>
                </div>
                <div>
                  <p className="mv__description">pool water volume</p>
                </div>
              </div>

              <div className="mv__content mv__last">
                <div className="mv__row">
                  <div>
                    <img
                      className="mv__i"
                      src={dimensions}
                      alt="dimensions icon"
                    />
                  </div>
                  <div>
                    <span className="mv__value">1,590</span>
                    <span className="mv__unit text-white">m&sup2;</span>
                  </div>
                </div>
                <div>
                  <p className="mv__description">pool area</p>
                </div>
              </div>
            </div>
          </div>

          <div className="mv__sns"></div>
          <div className="mv__side"></div>
        </div>

        {/* <div className="w-full absolute bottom-0 left-0 flex justify-center z-30">
            <div className="mv__scroll">
                <p className="mv__scroll--text font-serif text-white">VIEW GALLERY</p>
                <div className="mv__scroll--icon"></div>
            </div>
        </div> */}

        <div className="mv__bottom" ref={msgEl}>
          <span className="mv__text font-serif">Stay tuned for updates</span>
        </div>

        <div className="mv__pagination-wrap">
          <div className="mv__pagination">
            <div
              id="mv-swiper-pagination"
              className="swiper-pagination pagination mt-0"
            ></div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Mainvisual;
