import { useState, useEffect, useRef } from 'react';
import gsap from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';
import './style.scss';
import playbutton from '../../play-button.svg';

function TeaserMovie() {
  const [isPlay, setIsPlay] = useState(false);
  const [vid, setVid] = useState(null);

  const targetEl = useRef();
  const vidRef = useRef();
  const vidPlayRef = useRef();

  useEffect(() => {
    gsap.registerPlugin(ScrollTrigger);
    gsap.fromTo(
      '.movie__bg',
      {
        opacity: 0,
      },
      {
        opacity: 1,
        duration: 2,
        scrollTrigger: {
          trigger: '.movie',
          start: 'top center',
        },
      }
    );

    gsap.fromTo(
      '.movie__wrap',
      {
        opacity: 0,
      },
      {
        opacity: 1,
        duration: 2,
        delay: 1,
        scrollTrigger: {
          trigger: '.movie',
          start: 'top center',
        },
      }
    );
  }, []);

  useEffect(() => {
    const mouseClickFunc = () => {
      if (isPlay) {
        gsap.from(vidRef.current, {
          opacity: 1,
        });
        gsap.to(vidRef.current, {
          opacity: 0,
          duration: 3,
          ease: 'Power4.easeInOut',
        });

        let fadeout = setInterval(() => {
          if (vidPlayRef.current.volume - 0.1 <= 0) {
            vidPlayRef.current.volume = 0;
            vidPlayRef.current.pause();
            clearInterval(fadeout);
          } else {
            vidPlayRef.current.volume -= 0.1;
          }
        }, 50);

        setTimeout(() => {
          setIsPlay(false);
        }, 3000);
      } else {
        setIsPlay(true);

        if (vid === null) {
          setVid(
            'https://cdn.botanicalpoolclub.com/files/01/videos/230322_KANPAKE_mono.mp4'
          );
          vidPlayRef.current.load();
        }

        gsap.from(vidRef.current, {
          opacity: 0,
        });
        gsap.to(vidRef.current, {
          opacity: 1,
          duration: 3,
          ease: 'Power1.easeOut',
        });

        setTimeout(() => {
          vidPlayRef.current.play();

          let fadein = setInterval(() => {
            if (vidPlayRef.current.volume + 0.1 >= 1) {
              vidPlayRef.current.volume = 1;
              clearInterval(fadein);
            } else {
              vidPlayRef.current.volume += 0.1;
            }
          }, 50);
        }, 1000);
      }
    };

    if (targetEl.current != null) {
      targetEl.current.addEventListener('click', mouseClickFunc);
    }

    return () => {
      if (targetEl.current != null) {
        targetEl.current.removeEventListener('click', mouseClickFunc);
      }
    };
  });
  return (
    <>
      <div className="movie w-screen relative" ref={targetEl}>
        <div className="movie__bg w-full h-full absolute">
          <img
            className="movie__shadow w-full h-full"
            src={`${process.env.PUBLIC_URL}/movie-thumbnail-01.jpg`}
          />
        </div>

        <div className="movie-mouse absolute w-full h-full top-0 left-0 z-10"></div>
        <div className="movie__wrap lg:hidden w-full h-full absolute flex justify-center items-center">
          <div className="flex items-center flex-col">
            <div className="block">
              <img src={playbutton} className="movie__play"></img>
            </div>
            <div className="block">
              <span className="sc-title text-white font-serif">
                PLAY THE VIDEO
              </span>
            </div>
          </div>
        </div>

        <div
          className="movie__vid"
          ref={vidRef}
          style={{ display: isPlay ? 'block' : 'none' }}
        >
          <div className="movie__vid-play">
            <video ref={vidPlayRef} loading="lazy">
              <source src={vid} type="video/mp4" />
            </video>
          </div>
        </div>
      </div>
    </>
  );
}

export default TeaserMovie;
