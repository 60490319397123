import Transition from '../../components/transition';
import Cursor from '../../components/Cursor';
import { BackgroundWhite } from '../../components/Background';
import Header from '../../components/menu';
import Weather from '../../components/weather';
import Footer from '../../components/footer';
import './style.scss';

function Contact() {
  document.title = 'CONTACT | BOTANICAL POOL CLUB | OFFICIAL TEASER SITE';
  return (
    <>
      <main>
        <Transition />
        <Cursor isColor />
        <Header logo="menu-logo-black" background="menu-white" />
        <Weather isColor />
        <div className="contact">
          <BackgroundWhite />
          <div className="contact__container">
            <div className="contact__side">
              <span className="font-serif uppercase section-title default-tracking vertical-rl">
                {/* CONTACT */}
              </span>
            </div>
            <div className="contact__content">
              <h1 className="contact__title font-serif leading-tight">
                WE ARE ALWAYS
                <br />
                READY TO HELP YOU.
              </h1>

              <p className="contact__text font-serif p2">
                お客さまからのご質問などをお問い合わせフォームにて受け付けております。必要事項をご記入の上、「
                SEND 」を押してください。
              </p>

              <iframe
                src="https://info.botanicalpoolclub.com/l/1012642/2023-01-19/v5"
                allowtransparency="true"
                title="Botanical Pool Club Contact"
              ></iframe>
            </div>
            <div className="contact__side"></div>
          </div>
        </div>
        <Footer
          color="footer-black"
          logo="footer-logo-black"
          border="footer-border-black"
        />
      </main>
    </>
  );
}
export default Contact;
