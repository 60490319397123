import { useEffect } from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import gsap from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import './style.scss';

function Features() {
  useEffect(() => {
    gsap.registerPlugin(ScrollTrigger);
    setAnimation1();
    setAnimation2();
    setAnimation3();
    setAnimation4();
  });

  const setAnimation1 = () => {
    gsap.fromTo(
      '.sc__title',
      { opacity: 0 },
      {
        opacity: 1,
        duration: 2,
        scrollTrigger: {
          trigger: '.sc',
          start: 'top center',
          end: 'bottom center',
        },
      }
    );
  };

  const setAnimation2 = () => {
    gsap.fromTo(
      '.sc__content1',
      { opacity: 0, y: 50 },
      {
        opacity: 1,
        y: 0,
        duration: 2,
        delay: 0.3,
        scrollTrigger: {
          trigger: '.sc',
          start: 'top center',
          end: 'bottom center',
        },
      }
    );
  };

  const setAnimation3 = () => {
    gsap.fromTo(
      '.sc__content2',
      { opacity: 0, y: 50 },
      {
        opacity: 1,
        y: 0,
        duration: 2,
        delay: 0.6,
        scrollTrigger: {
          trigger: '.sc',
          start: 'top center',
          end: 'bottom center',
        },
      }
    );
  };

  const setAnimation4 = () => {
    gsap.fromTo(
      '.sc__content3',
      { opacity: 0, y: 50 },
      {
        opacity: 1,
        y: 0,
        duration: 2,
        delay: 0.9,
        scrollTrigger: {
          trigger: '.sc',
          start: 'top center',
          end: 'bottom center',
        },
      }
    );
  };
  return (
    <>
      <div className="sc">
        <div className="sc__container">
          <div className="sc__contents">
            <h1 className="sc__title sc-title text-white font-serif block">
              More Features
            </h1>

            <div className="sc__swiper">
              <Swiper
                slidesPerGroup={1}
                slidesPerView={1.12}
                spaceBetween={0}
                loop={true}
                breakpoints={{
                  1024: {
                    slidesPerGroup: 3,
                    slidesPerView: 3,
                  },
                  640: {
                    slidesPerGroup: 1,
                    slidesPerView: 2.3,
                  },
                }}
              >
                <SwiperSlide>
                  <div className="sc__content sc__content1 text-white font-serif">
                    <div className="sc__item">
                      <div className="sc__overlay">
                        {/* <div>
                          <p className="font-serif text-white caption sc__text">
                            POOLSIDE&nbsp;BAR
                          </p>
                        </div> */}
                      </div>
                      <img
                        className="sc__img"
                        src={`${process.env.PUBLIC_URL}/MoreFeatures_PoolsideBar.jpg`}
                      />
                    </div>
                    <h2 className="sc__facilities">POOLSIDE&nbsp;BAR</h2>
                    <p className="sc__text">
                      大切な人と特別な時間を過ごしたいなら、きめ細やかに弾ける上質なシャンパンを。
                      燃えるようなサンセットタイムに飲むなら、情熱的な強めのカクテルを。
                      プールサイドバーでは、その瞬間を味わえるドリンクをご用意。
                      体験してもらいたいのは、ワンランク上の煌めくプールシーン。
                    </p>
                  </div>
                </SwiperSlide>
                <SwiperSlide>
                  <div className="sc__content sc__content2 text-white font-serif">
                    <div className="sc__item">
                      <div className="sc__overlay">
                        {/* <div>
                          <p className="font-serif text-white caption sc__text">
                            POOL&nbsp;&&nbsp;SAUNA
                          </p>
                        </div> */}
                      </div>
                      <img
                        className="sc__img"
                        src={`${process.env.PUBLIC_URL}/MoreFeatures_PoolSauna.jpg`}
                      />
                    </div>
                    <h2 className="sc__facilities">POOL&nbsp;&&nbsp;SAUNA</h2>
                    <p className="sc__text">
                      プール上がりの身体を温めるのは、ジャングルをイメージしたBOTANICAL
                      POOL CLUBのサウナ。
                      スイムスーツのまま男女一緒に楽しめる場所。
                      野生に還り、日頃のストレスを解き放とう。
                      心と身体がデトックスされ、
                      素の自分に出逢えるサウナがあなたを待っている。
                    </p>
                  </div>
                </SwiperSlide>
                <SwiperSlide>
                  <div className="sc__content sc__content3 text-white font-serif">
                    <div className="sc__item">
                      <div className="sc__overlay">
                        {/* <div>
                          <p className="font-serif text-white caption sc__text">
                            POOLSIDE&nbsp;GAZEBO
                          </p>
                        </div> */}
                      </div>
                      <img
                        className="sc__img"
                        src={`${process.env.PUBLIC_URL}/MoreFeatures_PoolsideGazebo.jpg`}
                      />
                    </div>
                    <h2 className="sc__facilities">POOL&nbsp;HOUSE</h2>
                    <p className="sc__text">
                      止まり木さながらの心地いいスペース。
                      プールから上がったら、心の羽根を休めてほしい。
                      風景、プール、植物に溶け込むプールハウスのデザインは、風を感じて本を読んだり、シャンパンやカクテルを楽しんだりするのにちょうどいい。
                      喧騒から離れて静かな時間を過ごしたい。
                      そんなゲストを、プライベート空間がより一層非日常へと誘う。
                    </p>
                  </div>
                </SwiperSlide>
              </Swiper>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Features;
