import { useEffect } from 'react';
import gsap from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';
import './style.scss';

function Concept() {
  useEffect(() => {
    gsap.registerPlugin(ScrollTrigger);
    setTextAnimation1();
    setTextAnimation2();
    setTextAnimation3();
    setTextAnimation4();
  }, []);

  const setTextAnimation1 = () => {
    gsap.fromTo(
      '.concept-gsap-trigger1',
      {
        opacity: 0,
        y: 0,
      },
      {
        opacity: 1,
        y: 0,
        duration: 2,
        scrollTrigger: {
          trigger: '.concept',
          start: 'top center',
          end: 'bottom center',
        },
      }
    );
  };

  const setTextAnimation2 = () => {
    gsap.fromTo(
      '.concept-gsap-trigger2',
      {
        opacity: 0,
        y: 0,
      },
      {
        opacity: 1,
        delay: 1,
        y: 0,
        duration: 2,
        scrollTrigger: {
          trigger: '.concept',
          start: 'top bottom',
          end: 'bottom center',
        },
      }
    );
  };

  const setTextAnimation3 = () => {
    gsap.fromTo(
      '.concept-gsap-trigger3',
      {
        opacity: 0,
        y: 0,
      },
      {
        opacity: 1,
        y: 0,
        duration: 2,
        scrollTrigger: {
          trigger: '.concept',
          start: 'top center',
          end: 'bottom center',
        },
      }
    );
  };

  const setTextAnimation4 = () => {
    gsap.fromTo(
      '.concept-gsap-trigger4',
      {
        opacity: 0,
        y: 0,
      },
      {
        opacity: 1,
        y: 0,
        duration: 2,
        scrollTrigger: {
          trigger: '.concept',
          start: 'top center',
          end: 'bottom center',
        },
      }
    );
  };

  return (
    <>
      <div className="concept w-screen h-full relative">
        <div className="concept__container mx-auto">
          <div className="concept__flex-wrapper">
            <div className="lg:w-6/12 w-full">
              <div className="concept__flex-adjustment lg:ml-7 text-white font-serif tracking-wider lg:leading-more-loose">
                <span className="concept-subtitle concept-gsap-trigger3 leading-none">
                  Be yourself deeply.
                </span>
                <h1 className="concept__title concept-gsap-trigger4 lg:leading-tight leading-none">
                  Deep Dive Dope
                </h1>
              </div>
            </div>
            <div className="lg:w-6/12 w-full">
              <div className="concept__body text-white font-serif p1 text-justify">
                <p className="concept-gsap-trigger1 mb-16">
                  鮮やかな体験をしよう。心が満たされる体験を。何かを「する」ことだけが目的じゃない。何もしないことにこそ価値がある。
                  <br />
                  Deep –
                  忘れかけていた本来の自分に出逢う、ディープな非日常体験。
                  <br />
                  Dive –
                  空・海・緑・風へ、思いっきりダイブしよう。大胆に、刺激的に。
                  <br />
                  Dope –
                  どこにもないドープな世界。上質とラフがバランスよく共存する。
                  <br />
                  心が満足する旅へ。BOTANICAL POOL CLUBが本当の贅沢へと誘う。
                </p>
                <p className="concept-gsap-trigger2 p-en concept__en">
                  How many experiences can a person have in a lifetime? An
                  experience that is worth it for you. "Doing" something is not
                  the only value. You don't have to do anything If your heart is
                  satisfied “Deep” Something you forgot in your busy life. It's
                  a deep breath, a blank space in your heart, and a search for
                  your desires. More than just a stay. Regain your identity
                  through extraordinary, deep experiences. “Dive” to the sea
                  that connects to the sky, to the lush greenery and wind, lean
                  your body to be sucked in. After clearing your head, let's
                  dive in. Be bold, be inspired, and unleash your mind and body.
                  "Dope" Monotone architecture and wild plants harmonize, a dope
                  world that doesn't exist anywhere. Fine and rough. seemingly
                  contradictory elements balanced, creative, coexisting. Embark
                  on a journey of indescribable satisfaction. BOTANICAL POOL
                  CLUB invites you to essential luxury.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Concept;
