import React from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import './index.scss';
import './_mixin.scss';
import ScrollToTop from './ScrollToTop';
import Home from './pages/Home';
import Contact from './pages/contact';
import Gallery from './pages/gallery';
import Mediakit from './pages/Mediakit';
import Thanks from './pages//thanks';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <BrowserRouter>
      <ScrollToTop />
      <Routes>
        <Route path={'/'} element={<Home />} />
        <Route path={'/contact'} element={<Contact />} />
        <Route path={'/gallery'} element={<Gallery />} />
        <Route path={'/mediakit'} element={<Mediakit />} />
        <Route path={'/contact/thanks'} element={<Thanks />} />
        <Route path={'/newsletter/thanks'} element={<Thanks />} />
      </Routes>
    </BrowserRouter>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
